// SweetAlerts2

$(document).on('turbolinks:load', () => {
  window.swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger mr-3'
    },
    buttonsStyling: false,
  })

  $('.sweetalert').click(function(e) {
    e.preventDefault();
    let userId = $(e.currentTarget).data("user-id");
    let urlMethod = $(e.currentTarget).data("url-method") || "DELETE";

    swalWithBootstrapButtons.fire({
      title: 'Ви впевнені?',
      text: "У вас не буде можливості відновити запис!",
      type: 'error',
      showCancelButton: true,
      confirmButtonText: 'Так, я впевнений!',
      cancelButtonText: 'Відмінити',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        $.ajax({
          type: urlMethod,
          url: $(e.currentTarget).attr('href'),
          data: {
            user_id: userId
          }
        })
      }
    })
  });
});
