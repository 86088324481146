$(document).on("turbolinks:load", () => {
  // submit Devise forms by press Enter
  if ( $(".devise-pages form").length ) {
    $(document).keypress(function(e) {
      let keycode = (e.keyCode ? e.keyCode : e.which);

      if( keycode == '13' )
        $("input[type=submit]").trigger("click");
    });

    $(".submit").click((e) => {
      e.preventDefault();
      $("input[type=submit]").trigger("click")
    });
  }

  initSelect2();

  // page loader
  if ( $(".page-with-loader").length ) loaderCicle();
  $("input[type=submit]").click(activateLoader);
});

function loaderCicle() {
  activateLoader();
  setTimeout(deactivateLoader, 750)
}

window.activateLoader = function activateLoader() {
  $(".blockOverlay").fadeIn();
}

window.deactivateLoader = function deactivateLoader() {
  $(".blockOverlay").fadeOut();
}

window.initSelect2 = function initSelect2() {
  $(".multiselect-dropdown").select2({
    theme: "bootstrap4",
    width: "100%"
  });
}
