// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

const images = require.context('../src/images', true)
const imagePath = (name) => images(name, true)
window.imagePath = imagePath

// Application stylesheets
import "stylesheets/application"

// jQuery
import $ from "jquery"
window.$ = $
window.jQuery = $

// Bootstrap
import "bootstrap"

// Toastr
import "javascripts/toastr"

// DataTables
import "datatables.net"
import "datatables.net-bs4"
import "datatables.net-responsive"
import "datatables.net-responsive-bs4"
import "datatables.net-buttons"
import "datatables.net-buttons/js/buttons.colVis"
import "datatables.net-buttons/js/dataTables.buttons"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import "bootstrap-table"
import "javascripts/tables"

// Theme Options
import "javascripts/architectui/default"
import "javascripts/theme-default"

// SweetAlerts2
window.Swal = Swal
import "javascripts/sweet-alerts"

// Forms Multi Select
import "select2"

// Moment JS
window.moment = moment

// Application javascripts
import "javascripts/google-maps"
import "javascripts/places/form"
import "javascripts/routes/form"
import "javascripts/application"
