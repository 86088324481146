window.initPlacesForm = function initPlacesForm() {
  scrollToWarningMsg();

  $tagsTooltip = $(".place-tags-tooltip");
  $tagsSelect  = $(".place-tags-select");

  if ( $(".place-tags-select :selected").length > 0 ) {
    initTagsInputEditForm();
  } else {
    initTagsInputCreateForm();
  }

  $("textarea.autosize-input").each((index, elem) => {
    resize(elem);
  });
}

function initTagsInputEditForm() {
  $tagsTooltip.hide();
  let selectedCategory = $(".place-category :selected");
  let tags = $tagsSelect.html();
  let options = $(tags).filter(`optgroup[label="${selectedCategory.text()}"]`).html();
  $tagsSelect.html(options);
  initSelect2();
}

function initTagsInputCreateForm() {
  let selectedCategory = $(".place-category :selected");
  let tags = $(".place-tags").html();
  let options = $(tags).filter(`optgroup[label="${selectedCategory.text()}"]`).html();

  if ( selectedCategory.index() > 0 ) {
    $tagsTooltip.hide();
    $tagsSelect.html(options);
    $tagsSelect.show();
    initSelect2();
  } else {
    $(".place-tags-select option").remove();
    $tagsTooltip.show();
    $tagsSelect.siblings(".select2").hide();
    $tagsSelect.hide();
  }
}

function resize(text){
	text.style.height = 'auto';
	text.style.height = text.scrollHeight + 5 +'px';
}

function scrollToWarningMsg() {
  if ( $(".field_with_errors").length ) {
    let $field = $(".field_with_errors").first();
    let positionTop = $field.offset().top - 100;

    $('body,html').animate({scrollTop: positionTop}, 800);
  }
}

$(document).on("change drop keypress keydown cut paste", "textarea.autosize-input", function() {
  resize(this);
});

$(document).on("change", ".place-category", initTagsInputCreateForm);
$(document).on("turbolinks:load", initPlacesForm);
