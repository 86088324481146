$(document).on("turbolinks:load", () => {
  if ( $("#map").length ) initMap();
});

$(document).on("change keydown focusout paste", "#place_coordinates", function() {
  let coordinates = $(this).val().replace(/\s/g, '').split(',');
  let lat = parseFloat(coordinates[0]);
  let lng = parseFloat(coordinates[1]);

  if ( !isNaN(lat) && !isNaN(lng) ) {
    let location = { lat: lat, lng: lng}

    addMarker(location);
    map.setCenter(location);
    map.setZoom(13)
  }
});

window.initMap = function initMap() {
  markers = [];

  if ( typeof(google) == 'undefined' || typeof(google.maps) == 'undefined' ) {
    $.get({
      url: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}`,
      dataType: 'script',
      success: loadMap
    });
  } else loadMap();
}

function loadMap() {
  let htmlElement = $("#place_coordinates");
  let coordinates = parseCoordinates(htmlElement);

  map = new google.maps.Map(document.getElementById('map'), { zoom: 12, center: coordinates });
  addMarker(coordinates);

  if ( $("form").length ) {
    map.addListener('click', function(event) {
      addMarker(event.latLng);
      $("#place_coordinates").val(`${event.latLng.lat()}, ${event.latLng.lng()}`);
    });
  }
}

function parseCoordinates(element) {
  let text = element.val().replace(/\s/g, '').split(',');
  let location = {
    lat: parseFloat(text[0] || 49.440934),
    lng: parseFloat(text[1] || 32.065377)
  }

  return location;
}

function addMarker(location) {
  clearMarkers();

  let marker = new google.maps.Marker({ map: map, position: location });
  markers.push(marker);
}

function clearMarkers() {
  setMapOnAll(null);
  markers = [];
}

function setMapOnAll(map) {
  for (var i = 0; i < markers.length; i++) {
    markers[i].setMap(map);
  }
}
