// Datatables

$(document).on('turbolinks:load', () => {
  "use strict";

  setTimeout(() => {
    $('.dataTable').DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.20/i18n/Ukrainian.json"
      }
    });
  }, 500);
});
