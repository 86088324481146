// Toastr

$(document).on("turbolinks:load", () => {
  toastr.remove();

  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-bottom-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "10000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };

  setTimeout(() => {
    $(".toastr").each((index, elem) => {
      let type = $(elem).data("type");
      let msg = $(elem).data("msg");

      toastr[type](msg, "Повідомлення");
    });
  }, 350);
});
